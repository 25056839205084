.monitor_image {
    width: 30vw;
}
.monitor {
    width: 30vw;
    height: 14.5vw;
    overflow-y: hidden;
    border: solid 0.4vw #333;
    border-radius: 0.5vw;
}

.stand {
    margin: 0.5vw;
}
.stand:before {
    content: "";
    display: block;
    position: relative;
    background: #222;
    width: 7.5vw;
    height: 3.75vw;
    top: 18.25vw;
    left: 11.25vw;
}
.stand:after {
    content: "";
    display: block;
    position: relative;
    background: #333;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
    width: 22.5vw;
    height: 1.25vw;
    top: 3.75vw;
    left: 3.75vw;
}

/*FOR LARGE DEVİCES*/

@media screen and (max-width: 768px) {
    .monitor_image {
        width: 40vw;
    }
    .monitor {
        width: 40vw;
        height: 19.33vw;
    }
    .stand:before {
        width: 10vw;
        height: 5vw;
        top: 24vw;
        left: 15vw;
    }
    .stand:after {
        width: 25vw;
        height: 1.5vw;
        top: 4vw;
        left: 7.5vw;
    }
}
