.mobile_image {
    width: 8.25vw;
}
.mobile {
    width: 8.25vw;
    height: 15vw;
    overflow-y: hidden;
    border: solid 0.4vw #333;
    border-radius: 0.5vw;
    outline: solid 0.1vw var(--body-color);
    position: relative;
    left: 2.5vw;
    top: 3.5vw;
}

/*FOR LARGE DEVİCES*/

@media screen and (max-width: 768px) {
    .mobile_image {
        width: 11vw;
    }
    .mobile {
        width: 11vw;
        height: 20vw;
        position: relative;
        left: 2.5vw;
    }
}
