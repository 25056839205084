.tablet_image {
    width: 13.5vw;
}
.tablet {
    width: 13.5vw;
    height: 13.5vw;
    overflow-y: hidden;
    border: solid 0.4vw #333;
    border-radius: 0.5vw;
    outline: solid 0.1vw var(--body-color);
    position: relative;
    right: 2.5vw;
}

@media screen and (max-width: 768px) {
    .tablet_image {
        width: 18vw;
    }
    .tablet {
        width: 18vw;
        height: 18vw;
        overflow-y: hidden;
        border: solid 0.4vw #333;
        border-radius: 0.5vw;
        outline: solid 0.1vw var(--body-color);
        position: relative;
        right: 2.5vw;
    }
}
