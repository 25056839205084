.mockup {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.project_title {
    text-align: center;
    margin-left: 2vw;
    margin-right: 2vw;
}

.slide {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.portfolio_icon {
    font-size: 2.8rem;
    cursor: pointer;
}

.portfoliodesc {
    width: 60vw;
    margin: 10vw 20vw;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
}
